import { useState } from 'react';
import PageListView from '../../components/Page/PageListView';
import c from 'classnames';
import { API_BASE_PATH } from '../../config/api';
import useStore from '../../stores';
import { useHistory } from 'react-router-dom';

export const getRoleColor = (role: string) => {
  let color = '';
  switch (role) {
    case 'user':
      color = 'bg-green-100';
      break;
    case 'practitioner':
      color = 'bg-purple-100';
      break;
    case 'admin':
      color = 'bg-blue-100';
      break;
    default:
      color = 'bg-yellow-100';
  }
  return color;
};

export default function PractitionerConvert() {
  const history = useHistory();
  const token = useStore(state => state.admin.token || '');
  const setAdmin = useStore(state => state.admin.setAdmin);
  const [error, setError] = useState<string | null>(null);

  const convertUserToPractitioner = async (id: string) => {
    const res = await fetch(`${API_BASE_PATH}/ops/admins/users/${id}/convert`, {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        role: 'practitioner',
      }),
    });
    if (res.status === 200) {
      const obj = await res.json();
      history.push(`/practitioners/${obj.user.id}`);
    } else if (res.status === 401) {
      await setAdmin(undefined, undefined);
    } else if (res.status === 500 || res.status === 404) {
      setError(
        'There was a problem converting this user, please try again later or contact support.',
      );
    } else {
      const { error } = await res.json();
      setError(error);
    }
  };

  const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Email', accessor: 'email' },
    { Header: 'First Name', accessor: 'first_name' },
    { Header: 'Last Name', accessor: 'last_name' },
    {
      Header: 'Roles',
      accessor: 'roles',
      Cell: ({ value }: { value: string[] }) => (
        <div>
          {value.map((role: string, index: number) => (
            <span
              key={index}
              className={c('text-sm rounded-2xl p-2', getRoleColor(role))}
            >
              {role}
            </span>
          ))}
        </div>
      ),
    },
    {
      Header: 'Convert',
      Cell: (data: {
        row: { original: { id: string; role: string; roles: string[] } };
      }) => {
        if (
          data.row.original.role !== 'user' ||
          data.row.original.roles.includes('practitioner') ||
          data.row.original.roles.includes('admin')
        ) {
          return null;
        }

        return (
          <div>
            <button
              onClick={() => convertUserToPractitioner(data.row.original.id)}
              className='h-10 px-2 lg:px-5 text-sm bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'
            >
              Convert
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <PageListView
      header='Convert User to Practitioner'
      error={error}
      tableProps={{
        type: 'users',
        route: 'users',
        columns: columns,
        disabled: () => true,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'Email',
            filter: 'email',
          },
          {
            label: 'First Name',
            filter: 'first_name',
          },
          {
            label: 'Last Name',
            filter: 'last_name',
          },
        ],
      }}
    />
  );
}
