import { NavLink } from 'react-router-dom';
import PageContainer from './PageContainer';
import PageHeader from './PageHeader';
import Table from '../Table';
import { FilterLabel } from '@sefirosweb/react-multiple-search';

interface PageListViewProps {
  header: string;
  rightButton?: {
    title: string;
    url: string;
  };
  error?: string | null;
  tableProps: {
    type: string;
    route?: string;
    columns?: any;
    defaultFilter?: any;
    filters?: any;
    onRowClick?: any;
    CustomRowComponent?: any;
    showCustomRowComponent?: any;
    searchable?: any;
    searchFilterLabels?: FilterLabel[];
    staticData?: any;
    disabled?: any;
  };
}

const PageListView = ({
  header,
  rightButton,
  error,
  tableProps,
}: PageListViewProps) => {
  return (
    <PageContainer>
      <div className='flex justify-between'>
        <PageHeader title={header} />
        {rightButton && (
          <NavLink to={rightButton.url}>
            <button className='flex h-10 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-transparent hover:bg-gray-200 rounded-md border border-solid border-gray-300 text-gray-900 transition-all duration-200 ease-in-out delay-75'>
              {rightButton.title}
            </button>
          </NavLink>
        )}
      </div>
      {error ? (
        <div className='bg-pink-50 outline-none shadow-md rounded-md border border-solid border-pink-500 mt-4'>
          <div className='flex flex-col p-3'>
            <div className='font-semibold'>There is 1 error:</div>
            <div className='ml-4 pl-4'>
              <ul className='list-disc'>
                <li className='mt-2'>{error}</li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}

      <Table {...tableProps} />
    </PageContainer>
  );
};

export default PageListView;
