import PageListView from '../../components/Page/PageListView';

const columns = [
  { Header: 'ID', accessor: 'id' },
  { Header: 'First Name', accessor: 'first_name' },
  { Header: 'Last Name', accessor: 'last_name' },
  { Header: 'Email', accessor: 'email' },
  { Header: 'Description', accessor: 'description' },
];

export default function ConsultAgents() {
  return (
    <PageListView
      header='Consult Agents'
      rightButton={{
        title: 'Create New',
        url: '/consultAgents/new',
      }}
      tableProps={{
        type: 'consultAgents',
        route: 'consult-agents',
        columns: columns,
        searchable: true,
        searchFilterLabels: [
          {
            label: 'ID',
            filter: 'id',
          },
          {
            label: 'First Name',
            filter: 'first_name',
          },
          {
            label: 'Last Name',
            filter: 'last_name',
          },
          {
            label: 'Email',
            filter: 'email',
          },
        ],
      }}
    />
  );
}
