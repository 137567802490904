import c from 'classnames';
import PageListView from '../../components/Page/PageListView';
import { formatDate } from '../../lib/utils/formatDate';

export const getStatusColor = status => {
  return status === 'Active' ? 'bg-green-100' : 'bg-red-100';
};

const columns = [
  { Header: 'Email', accessor: row => row.user?.email },
  { Header: 'First name', accessor: row => row.user?.first_name },
  { Header: 'Last name', accessor: row => row.user?.last_name },
  { Header: 'Organization', accessor: row => row.organization },
  {
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <div>
        <span className={c('text-sm rounded-2xl p-2', getStatusColor(value))}>
          {value}
        </span>
      </div>
    ),
  },
  {
    Header: 'Linked accounts',
    accessor: row => row.mainaccounts?.length ?? 0,
  },
  {
    Header: 'Created at',
    accessor: row => formatDate(row.created_at),
  },
];

export default function Practitioners() {
  return (
    <PageListView
      header='Practitioners'
      rightButton={{
        title: 'Convert User to Practitioner',
        url: '/practitioners/convert',
      }}
      tableProps={{
        type: 'practitioners',
        route: 'practitioners',
        columns: columns,
        searchable: true,
      }}
    />
  );
}
