import React, { useState } from 'react';

import useStore from '../../stores';
import { API_BASE_PATH } from '../../config/api';
import { Link } from 'react-router-dom';
import PageContainer from '../../components/Page/PageContainer';
import Table from '../../components/Table/index';
import PageHeader from '../../components/Page/PageHeader';
import moment from 'moment-timezone';

const columns = [
  {
    Header: 'Order Date',
    accessor: 'order_date',
    Cell: ({ value }) => {
      const order_date_hours_old = value
        ? moment().diff(moment(value), 'hours')
        : 0;

      return (
        <div
          className={order_date_hours_old > 72 ? 'text-red-700 font-bold' : ''}
        >
          {value}
        </div>
      );
    },
  },
  {
    Header: 'Type',
    accessor: 'shipping_service',
  },
  {
    Header: 'Country',
    accessor: 'country_code',
  },
  {
    Header: 'Partner',
    accessor: row => (row.partner ? row.partner.name : ''),
  },
  {
    Header: 'Pending Fulfillments',
    accessor: 'pending_fulfillments',
  },
  {
    Header: 'Print File',
    accessor: row => row,
    Cell: ({ value }) => {
      const date_last_generated_minutes_old = value.date_last_generated
        ? moment()
            .tz(moment.tz.guess())
            .diff(
              moment.tz(
                moment.utc(value.date_last_generated),
                moment.tz.guess(),
              ),
              'seconds',
            )
        : 0;

      return !value.label_url || date_last_generated_minutes_old > 300 ? (
        <GenerateOutboundLabel id={value.id} />
      ) : (
        <UpdateOutboundLabel outboundLabel={value} />
      );
    },
  },
];

const generateOutboundLabel = async (id, token) => {
  const res = await fetch(
    `${API_BASE_PATH}/ops/admins/outbound-labels/${id}/generate`,
    {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'x-access-token': token,
        'Content-Type': 'application/json',
      },
    },
  );
  if (res.status === 200) {
    window.location.reload();
  }
  return { outboundLabels: null };
};

const GenerateOutboundLabel = ({ id }) => {
  const token = useStore(state => state.admin.token);
  const [disableClick, setDisableClick] = useState(false);

  return (
    <button
      className='text-xs h-8 mt-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
      onClick={async () => {
        setDisableClick(true);
        await generateOutboundLabel(id, token);
      }}
      disabled={disableClick}
    >
      Generate
    </button>
  );
};

const UpdateOutboundLabel = ({ outboundLabel }) => {
  const token = useStore(state => state.admin.token);
  const [disableClick, setDisableClick] = useState(false);

  return (
    <div>
      <ul>
        <li>
          <Link
            to={{
              pathname: `${outboundLabel.label_url}`,
            }}
            target='_blank'
            rel='noopener noreferrer'
            className='text-sm text-green-300 underline hover:text-green-400'
          >
            Download
          </Link>
        </li>
        <li>
          <div className='text-xs italic'>
            {outboundLabel.date_last_generated}
          </div>
        </li>
        <li>
          <button
            className='text-xs h-8 mt-2 justify-center items-center relative overflow-hidden px-2 lg:px-5 text-sm min-w-content bg-purple-500 enabled:hover:bg-purple-600 rounded-md border border-solid border-purple-500 text-white transition-all duration-200 ease-in-out delay-75 disabled:opacity-25'
            onClick={async () => {
              setDisableClick(true);
              generateOutboundLabel(outboundLabel.id, token);
            }}
            disabled={disableClick}
          >
            Update
          </button>
        </li>
      </ul>
    </div>
  );
};

export default function KitPrep() {
  return (
    <PageContainer>
      <div className='flex justify-between'>
        <PageHeader title={'Fulfillment: Outbound Labels'} />
      </div>
      <Table
        type={'outboundLabels'}
        route={'outbound-labels'}
        columns={columns}
        searchable={true}
        onRowClick={() => {
          return;
        }}
      />
    </PageContainer>
  );
}
